import axios from "axios";

const localUrl = process.env.REACT_APP_LOCAL_URL;
const productionUrl = "/";

//******************************/
//Api Config
//******************************/
export const setToken = (token) => {
  API.interceptors.request.use(async (req) => {
    //   if (localStorage.getItem('profile')) {
    //     req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    //   }
    //const token = setToken

    const config = {
      "Content-type": "application/json",
      "x-auth-token": token,
    };

    req.headers = config;

    return req;
  });
};

const API = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? productionUrl : localUrl,
});

//******************************/
//Auth Api Requests// 
//******************************/

//Auth Api Requests
// export const registerUser = () => API.post(`/api/auth`)
export const authUser = (data) => API.post(`/api/auth/loginadmin`, data)
// export const updatePassword = (data) => API.post(`/api/auth/updatepass`, data)
// export const updateUserData = (data) => API.post(`/api/users/update`, data)
export const getUploadUrl = async (data) => {
  const response = await API.post(`/api/getuploadurl`, data);
  return response.data;
};


////////////////////////////////
//Stats Api Requests
////////////////////////////////

//PROT
export const getUserStats = async ({ 
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/stats/userstats?municipalityId=${municipalityId}`
  );
  console.log(response.data);
  return response.data;
};



////////////////////////////////
//Municipality Api Requests
////////////////////////////////
export const getLiveMunicipalities = async () => {
  const response = await API.get(`/api/municipality/live`);
  return response.data;
};

export const getAllMunicipalities = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
}) => {
  const response = await API.get(
    `/api/municipality?page=${page.toString()}&limit=${limit.toString()}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};

//PROT
export const addMunicipality = async (data) => {
  const response = await API.post(`/api/municipality`, data);
  return response.data;
};

//PROT
export const updateMunicipality = async ({id,data}) => {
  const response = await API.post(`/api/municipality?id=${id.toString()}`, data);
  return response.data;
};

//PROT
export const deleteMunicipality = async (id) => {
  const response = await API.delete(`/api/municipality?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Areas Api Requests
////////////////////////////////

//PROT
export const getAllAreas = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/areas?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  return response.data;
};


//PROT
export const addArea = async (data) => {
  const response = await API.post(`/api/areas?`, data);
  return response.data;
};

//PROT
export const updateArea = async ({id,data}) => {
  const response = await API.post(`/api/areas?id=${id.toString()}`, data);
  return response.data;
};

//PROT
export const deleteArea = async (id) => {
  const response = await API.delete(`/api/areas?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Mayor Api Requests
////////////////////////////////

//PROT
export const getAllMayors = async ({
  
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/mayor?municipalityId=${municipalityId}`
  );
  return response.data;
};

//PROT
export const addMayor = async (data) => {
  const response = await API.post(`/api/mayor?`, data);
  return response.data;
};

//PROT
export const updateMayor = async ({id,data}) => {
  const response = await API.post(`/api/mayor?id=${id.toString()}`, data);
  return response.data;
};

//PROT
export const deleteMayor= async (id) => {
  const response = await API.delete(`/api/mayor?id=${id.toString()}`);
  return response.data;
};

////////////////////////////////
//Lookup Api Requests
////////////////////////////////
export const getLookupsByType = async ({
  lookupType
}) => {
  const response = await API.get(
    `/api/lookup?lookupType=${lookupType.toString()}`
  );
  console.log(response.error);
  return response.data;
};

////////////////////////////////
//OfferTypes Api Requests
////////////////////////////////
export const getAllOfferTypes = async () => {
  const response = await API.get(
    `/api/offertypes`
  );
  console.log(response.error);
  return response.data;
};


////////////////////////////////
//Materials Api Requests
////////////////////////////////
export const getAllMaterials = async () => {
  const response = await API.get(
    `/api/materials`
  );
  console.log(response.error);
  return response.data;
};


////////////////////////////////
//CitizenTransactions Api Requests
////////////////////////////////

//PROT - CLAIMED BY USER MIDDLEWARE
// export const getCitizenTransactions = async ({
//   citizenId
// }) => {
//   const response = await API.get(
//     `/api/citizentransactions?citizenId=${citizenId}`
//   );
//   console.log(response.error);
//   return response.data;
// };


//PROT - CLAIMED BY USER MIDDLEWARE
// export const addCitizenTransaction  = async (data) => {
//   const response = await API.post(`/api/citizentransactions`, data);
//   return response.data;
// };



////////////////////////////////
//Citizen Savings Api Requests
////////////////////////////////

//PROT - CLAIMED BY USER MIDDLEWARE
export const getCitizenSavings = async ({
  citizenId
}) => {
  const response = await API.get(
    `/api/citizensavings?citizenId=${citizenId}`
  );
  console.log(response.error);
  return response.data;
};

//PROT
export const addCitizenSaving  = async (data) => {
  const response = await API.post(`/api/citizensavings/addminpoints`, data);
  return response.data;
};



////////////////////////////////
//Citizen Account Api Requests
////////////////////////////////

//PROT
export const getCitizenAccount = async ({
  citizenId
}) => {
  const response = await API.get(
    `/api/citizensaccount?citizenId=${citizenId}`
  );
  console.log(response.error);
  return response.data;
};

//PROT
export const getTransactionsAdmin = async ({
  citizenId
}) => {
  const response = await API.get(
    `/api/citizensavings/getadminpoints?citizenId=${citizenId}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addCitizenAccount  = async (data) => {
  const response = await API.post(`/api/citizensaccount`, data);
  return response.data;
};


//PROT
export const updateCitizenAccount = async ({id,data}) => {
  const response = await API.post(`/api/citizensaccount?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteitizenAccount = async (id) => {
  const response = await API.delete(`/api/citizensaccount?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//Coupons Api Requests
////////////////////////////////

//PROT
export const getAllCoupons = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/publicOrganizationToken?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  return response.data;
};

//PROT
export const addCoupon = async (data) => {
  const response = await API.post(`/api/publicOrganizationToken`, data);
  return response.data;
};

//PROT
export const updateCoupon = async ({id,data}) => {
  const response = await API.post(`/api/publicOrganizationToken?id=${id.toString()}`, data);
  return response.data;
};

//PROT
export const deleteCoupon = async (id) => {
  const response = await API.delete(`/api/publicOrganizationToken?id=${id.toString()}`);
  return response.data;
};




////////////////////////////////
//News Api Requests
////////////////////////////////
export const getAllNews = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/news?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  return response.data;
};

//PROT
export const addNews = async (data) => {
  console.log("ADDING NEWS");
  const response = await API.post(`/api/news`, data);
  return response.data;
};

//PROT
export const updateNews = async ({id,data}) => {
  const response = await API.post(`/api/news?id=${id.toString()}`, data);
  return response.data;
};

//PROT
export const deleteNews = async (id) => {
  console.log("ID is " + id);
  const response = await API.delete(`/api/news?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//Blog Api Requests
////////////////////////////////
export const getAllBlogs = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
  missionOnly = 0,
}) => {
  const response = await API.get(
    `/api/blog?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}&missionOnly=${missionOnly}`
  );
  console.log(response.error);
  return response.data;
};

//PROT
export const addBLog = async (data) => {
  const response = await API.post(`/api/blog?`, data);
  return response.data;
};


//PROT
export const updateBlog = async ({id,data}) => {
  const response = await API.post(`/api/blog?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteBlog = async (id) => {
  const response = await API.delete(`/api/blog?id=${id.toString()}`);
  return response.data;
};

////////////////////////////////
//Quiz Api Requests
////////////////////////////////
export const getAllQuizes = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
  missionOnly = 0,
}) => {
  const response = await API.get(
    `/api/quiz?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}&missionOnly=${missionOnly}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addQuiz = async (data) => {
  const response = await API.post(`/api/quiz?`, data);
  return response.data;
};


//PROT
export const updateQuiz = async ({id,data}) => {
  const response = await API.post(`/api/quiz?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteQuiz = async (id) => {
  const response = await API.delete(`/api/quiz?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//QuizQuestions Api Requests
////////////////////////////////
export const getAllQuizQuestions = async ({
 
  quizid = 0,
}) => {
  const response = await API.get(
    `/api/quiz/questions?quizid=${quizid}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addQuizQuestion = async (data) => {
  const response = await API.post(`/api/quiz/questions?`, data);
  return response.data;
};


//PROT
export const updateQuizQuestion = async ({id,data}) => {
  const response = await API.post(`/api/quiz/questions?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteQuizQuestion = async (id) => {
  const response = await API.delete(`/api/quiz/questions?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Quiz Anwers Api Requests
////////////////////////////////
export const getAllQuizAnswers = async ({
 
  questionid = 0,
}) => {
  const response = await API.get(
    `/api/quiz/answers?questionid=${questionid}`
  );
  console.log("Answers:",response.data.row);
  return response.data;
};


//PROT
export const addQuizAnswer = async (data) => {
  const response = await API.post(`/api/quiz/answers?`, data);
  return response.data;
};


//PROT
export const updateQuizAnswer = async ({id,data}) => {
  const response = await API.post(`/api/quiz/answers?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteQuizAnswer = async (id) => {
  const response = await API.delete(`/api/quiz/answers?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Votes Api Requests
////////////////////////////////
export const getAllVotes = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/votes?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addVote = async (data) => {
  const response = await API.post(`/api/votes?`, data);
  return response.data;
};


//PROT
export const updateVote = async ({id,data}) => {
  const response = await API.post(`/api/votes?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteVote = async (id) => {
  const response = await API.delete(`/api/votes?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Votes Options Api Requests
////////////////////////////////
export const getAllVoteOptions = async ({
  voteId = 0,
  
}) => {
  const response = await API.get(
    `/api/voteoptions?voteId=${voteId}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addVoteOption = async (data) => {
  const response = await API.post(`/api/voteoptions?`, data);
  return response.data;
};


//PROT
export const updateVoteOption = async ({id,data}) => {
  const response = await API.post(`/api/voteoptions?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteVoteOption = async (id) => {
  const response = await API.delete(`/api/voteoptions?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//Videos Api Requests
////////////////////////////////
export const getAllVideos = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
  missionOnly = 0,
}) => {
  const response = await API.get(
    `/api/videos?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}&missionOnly=${missionOnly}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addVideo = async (data) => {
  const response = await API.post(`/api/videos?`, data);
  return response.data;
};


//PROT
export const updateVideo = async ({id,data}) => {
  const response = await API.post(`/api/videos?id=${id.toString()}`, data);
  return response.data;
};

export const deleteVideo = async (id) => {
  const response = await API.delete(`/api/videos?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Merchants Api Requests
////////////////////////////////
export const getAllMerchants = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/merchants?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addMerchant = async (data) => {
  const response = await API.post(`/api/merchants?`, data);
  return response.data;
};


//PROT
export const updateMerchant = async ({id,data}) => {
  const response = await API.post(`/api/merchants?id=${id.toString()}`, data);
  return response.data;
};


//PROT
export const deleteMerchant = async (id) => {
  const response = await API.delete(`/api/merchants?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Offers Api Requests
////////////////////////////////
export const getAllOffers = async ({
  merchantId = 0,
  
}) => {
  const response = await API.get(
    `/api/offers?merchantId=${merchantId}`
  );
  console.log(response.error);
  return response.data;
};


export const getAllOfferStats = async ({
  municipalityId = 0,
  
}) => {
  const response = await API.get(
    `/api/offers/getstats?municipalityId=${municipalityId}`
  );
  console.log(response.error);
  return response.data;
};


//PROT
export const addOffer = async (data) => {
  const response = await API.post(`/api/offers?`, data);
  return response.data;
};


//PROT
export const updateOffer = async ({id,data}) => {
  const response = await API.post(`/api/offers?id=${id.toString()}`, data);
  return response.data;
};

//PROT
export const deleteOffer = async (id) => {
  const response = await API.delete(`/api/offers?id=${id.toString()}`);
  return response.data;
};

////////////////////////////////
//Citizen Offers/Redemptions Api Requests - for stats
////////////////////////////////
export const getAllMerchantRedemptions= async ({ merchantId }) => {
  const response = await API.get(`/api/citizenoffers/redemptionstats?merchantId=${merchantId}`);

  return response.data;
};
export const getAllMerchantRedemptionsByMunicipality= async ({ municipalityId }) => {
  const response = await API.get(`/api/citizenoffers/redemptionStatsAllMerchants?municipalityId=${municipalityId}`);

  return response.data;
};


////////////////////////////////
//Schools Api Requests
////////////////////////////////
export const getAllSchools = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/schools?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};

export const addSchool = async (data) => {
  const response = await API.post(`/api/schools?`, data);
  return response.data;
};

export const updateSchool = async ({id,data}) => {
  const response = await API.post(`/api/schools?id=${id.toString()}`, data);
  return response.data;
};

export const deleteSchool = async (id) => {
  const response = await API.delete(`/api/schools?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//School Users Api Requests
////////////////////////////////
export const getAllSchoolUsers= async ({
  schoolId = 0,
  
}) => {
  const response = await API.get(
    `/api/schoolusers?schoolId=${schoolId}`
  );
  console.log(response.error);
  return response.data;
};

export const addSchoolUser = async (data) => {
  const response = await API.post(`/api/schoolusers?`, data);
  return response.data;
};

export const updateSchoolUser = async ({id,data}) => {
  const response = await API.post(`/api/schoolusers?id=${id.toString()}`, data);
  return response.data;
};

export const deleteSchoolUser = async (id) => {
  const response = await API.delete(`/api/schoolusers?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//School Contests Api Requests
////////////////////////////////
export const getAllSchoolContests = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/schoolcontests?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};

export const addSchoolContest = async (data) => {
  const response = await API.post(`/api/schoolcontests?`, data);
  return response.data;
};

export const updateSchoolContest = async ({id,data}) => {
  const response = await API.post(`/api/schoolcontests?id=${id.toString()}`, data);
  return response.data;
};

export const deleteSchoolContest = async (id) => {
  const response = await API.delete(`/api/schoolcontests?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//School Projects Api Requests
////////////////////////////////
export const getAllSchoolProjects = async ({
  schoolContestId = 0,
 }) => {
  const response = await API.get(
    `/api/schoolprojects?schoolContestId=${schoolContestId}`
  );
  console.log(response.error);
  return response.data;
};

export const addSchoolProject = async (data) => {
  const response = await API.post(`/api/schoolprojects`, data);
  return response.data;
};

export const updateSchoolProject = async ({id,data}) => {
  const response = await API.post(`/api/schoolprojects?id=${id.toString()}`, data);
  return response.data;
};

export const deleteSchoolProject = async (id) => {
  const response = await API.delete(`/api/schoolprojects?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//School Transactions Api Requests
////////////////////////////////
export const getAllSchoolTransactions = async ({
  schoolProjectId = 0,
 }) => {
  const response = await API.get(
    `/api/schooltransactions?schoolProjectId=${schoolProjectId}`
  );
  console.log(response.error);
  return response.data;
};

export const addSchoolTransaction = async (data) => {
  const response = await API.post(`/api/schooltransactions`, data);
  return response.data;
};


////////////////////////////////
//Sport Club Api Requests
////////////////////////////////
export const getAllSportclubs = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/sportclubs?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};

export const addSportclub = async (data) => {
  const response = await API.post(`/api/sportclubs?`, data);
  return response.data;
};

export const updateSportclub = async ({id,data}) => {
  const response = await API.post(`/api/sportclubs?id=${id.toString()}`, data);
  return response.data;
};

export const deletelSportclub = async (id) => {
  const response = await API.delete(`/api/sportclubs?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Sport Club Contests Api Requests
////////////////////////////////
export const getAllSportclubContests = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/sportclubcontests?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};

export const addSportclubContest = async (data) => {
  const response = await API.post(`/api/sportclubcontests?`, data);
  return response.data;
};

export const updateSportclubContest = async ({id,data}) => {
  const response = await API.post(`/api/sportclubcontests?id=${id.toString()}`, data);
  return response.data;
};

export const deleteSportclubContest = async (id) => {
  const response = await API.delete(`/api/sportclubcontests?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//School Projects Api Requests
////////////////////////////////
export const getAllSportClubProjects = async ({
  sportClubContestId = 0,
 }) => {
  const response = await API.get(
    `/api/sportclubprojects?sportClubContestId=${sportClubContestId}`
  );
  console.log(response.error);
  return response.data;
};

export const addSportClubProject = async (data) => {
  const response = await API.post(`/api/sportclubprojects`, data);
  return response.data;
};

export const updateSportClubProject = async ({id,data}) => {
  const response = await API.post(`/api/sportclubprojects?id=${id.toString()}`, data);
  return response.data;
};

export const deleteSportClubProject = async (id) => {
  const response = await API.delete(`/api/sportclubprojects?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//Citizens Api Requests
////////////////////////////////
export const getAllCitizens = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
  areaId = 0,
}) => {
  const response = await API.get(
    `/api/citizens?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}&areaId=${areaId}`
  );
  console.log(response.error);
  return response.data;
};

export const addCitizen = async (data) => {
  const response = await API.post(`/api/citizens?`, data);
  return response.data;
};

export const updateCitizen = async ({id,data}) => {
  const response = await API.post(`/api/citizens?id=${id.toString()}`, data);
  return response.data;
};

export const deleteCitizen = async (id) => {
  const response = await API.delete(`/api/citizens?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Citizens Api Requests
////////////////////////////////
export const getAllPublicOrganizations = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/publicorganizations?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.error);
  return response.data;
};

export const addPublicOrganization = async (data) => {
  const response = await API.post(`/api/publicorganizations?`, data);
  return response.data;
};

export const updatePublicOrganization = async ({id,data}) => {
  const response = await API.post(`/api/publicorganizations?id=${id.toString()}`, data);
  return response.data;
};

export const deletePublicOrganization = async (id) => {
  const response = await API.delete(`/api/publicorganizations?id=${id.toString()}`);
  return response.data;
};



////////////////////////////////
//Recycle Buckets Api Requests
////////////////////////////////
export const getAllRecycleBuckets = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/recyclebuckets?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.data);
  return response.data;
};

export const addRecycleBucket = async (data) => {
  const response = await API.post(`/api/recyclebuckets?`, data);
  return response.data;
};

export const updateRecycleBucket = async ({id,data}) => {
  const response = await API.post(`/api/recyclebuckets?id=${id.toString()}`, data);
  return response.data;
};

export const deleteRecycleBucket = async (id) => {
  const response = await API.delete(`/api/recyclebuckets?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//WasteCollectionReport Api Requests
////////////////////////////////
export const getAllWasteCollectionReports = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/wastecollectionreport?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.data);
  return response.data;
};

export const addWasteCollectionReport = async (data) => {
  const response = await API.post(`/api/wastecollectionreport?`, data);
  return response.data;
};

export const updateWasteCollectionReport = async ({id,data}) => {
  const response = await API.post(`/api/wastecollectionreport?id=${id.toString()}`, data);
  return response.data;
};

export const deleteWasteCollectionReport = async (id) => {
  const response = await API.delete(`/api/wastecollectionreport?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//WasteCollectionReport Api Requests
////////////////////////////////
export const getAllNationalCoupons = async ({
   merchantId = 720,
}) => {
  const response = await API.get(
    `/api/nationalcoupons?merchantId=${merchantId}`
  );
  console.log(response.data);
  return response.data;
};

export const addNationalCoupon = async (data) => {
  const response = await API.post(`/api/nationalcoupons?`, data);
  return response.data;
};

export const updateNationalCoupon = async ({id,data}) => {
  const response = await API.post(`/api/nationalcoupons?id=${id.toString()}`, data);
  return response.data;
};

export const deleteNationalCoupon = async (id) => {
  const response = await API.delete(`/api/nationalcoupons?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//User Posts Requests
////////////////////////////////
export const getAllUserPosts  = async ({
  limit = 0,
  page = 0,

}) => {
  const response = await API.get(
    `/api/userposts?page=${page.toString()}&limit=${limit.toString()}`
  );
  
  return response.data;
};

export const addUserPost = async (data) => {
 const response = await API.post(`/api/userposts?`, data);
 return response.data;
};

export const updateUserPost = async ({id,data}) => {
 const response = await API.post(`/api/userposts?id=${id.toString()}`, data);
 return response.data;
};

export const deleteUserPost = async (id) => {
 const response = await API.delete(`/api/userposts?id=${id.toString()}`);
 return response.data;
};



////////////////////////////////
//Missions Api Requests
////////////////////////////////
export const getAllMissions = async ({
  limit = 0,
  page = 0,
  optionalKeyword = null,
  municipalityId = 0,
}) => {
  const response = await API.get(
    `/api/missions?page=${page.toString()}&limit=${limit.toString()}&municipalityId=${municipalityId}&optionalKeyword=${optionalKeyword}`
  );
  console.log(response.data);
  return response.data;
};

export const addMissionn = async (data) => {
  const response = await API.post(`/api/missions?`, data);
  return response.data;
};

export const updateMission = async ({id,data}) => {
  const response = await API.post(`/api/missions?id=${id.toString()}`, data);
  return response.data;
};

export const deleteMission= async (id) => {
  const response = await API.delete(`/api/missions?id=${id.toString()}`);
  return response.data;
};

export const getAllMunicipalityMissions = async ({
 
  missionId
}) => {
  const response = await API.get(
    `/api/municipalitymission?missionId=${missionId}`
  );
  console.log(response.data);
  return response.data;
};

export const getMissionLotteryUsers = async ({
 
  missionId,
  municipalityId,
  citizenId
}) => {
  const response = await API.get(
    `/api/missions/getlotteryusers?missionId=${missionId}&municipalityId=${municipalityId}&citizenId=${citizenId}`
  );
  console.log(response.data);
  return response.data;
};


export const getMissionStats = async ({
 
  missionId,
  
}) => {
  const response = await API.get(
    `/api/missions/getstats?missionId=${missionId}`
  );

  return response.data;
};



export const addMunicipalityMission = async (data) => {
  const response = await API.post(`/api/municipalitymission?`, data);
  return response.data;
};

export const updateMunicipalityMission = async ({id,data}) => {
  const response = await API.post(`/api/municipalitymission?id=${id.toString()}`, data);
  return response.data;
};

export const deleteMunicipalityMission= async (id) => {
  const response = await API.delete(`/api/municipalitymission?id=${id.toString()}`);
  return response.data;
};


////////////////////////////////
//Missions goals Api Requests
////////////////////////////////
export const getAllMissionsGoals = async ({
 
  missionId
}) => {
  const response = await API.get(
    `/api/missiongoal?missionId=${missionId}`
  );
  console.log(response.data);
  return response.data;
};

export const addMissionnGoal = async (data) => {
  const response = await API.post(`/api/missiongoal?`, data);
  return response.data;
};

export const updateMissionGoal = async ({id,data}) => {
  const response = await API.post(`/api/missiongoal?id=${id.toString()}`, data);
  return response.data;
};

export const deleteMissionGoal= async (id) => {
  const response = await API.delete(`/api/missiongoal?id=${id.toString()}`);
  return response.data;
};


export const getAllMissionsGoalsTypes = async () => {
  const response = await API.get(
    `/api/missiongoaltype`
  );
  console.log(response.data);
  return response.data;
};




////////////////////////////////
//Public Organization User Users Api Requests
////////////////////////////////
export const getAllPublicOrganizationUsers= async ({
  publicOrganizationId = 0,
  
}) => {
  const response = await API.get(
    `/api/publicOrganizationUser?publicOrganizationId=${publicOrganizationId}`
  );
  console.log(response.error);
  return response.data;
};

export const addPublicOrganizationUser = async (data) => {
  const response = await API.post(`/api/publicOrganizationUser?`, data);
  return response.data;
};

export const updatePublicOrganizationUser = async ({id,data}) => {
  const response = await API.post(`/api/publicOrganizationUser?id=${id.toString()}`, data);
  return response.data;
};

export const deletePublicOrganizationUser = async (id) => {
  const response = await API.delete(`/api/publicOrganizationUser?id=${id.toString()}`);
  return response.data;
};
